jQuery.noConflict();
(function( $ ) {

	/* 
	-------------------------------------
	FUNCTIONS
	-------------------------------------
	*/

		
	/* 
	-------------------------------------
	DOCUMENT READY
	-------------------------------------
	*/
	
	$(document).ready(function(){
		
		/* match height */
		$(".alpha, .beta").matchHeight();
		$(".quote, .signature").matchHeight();
		
		/* slick */
		$('.slider').slick({
			slide: 'div',
			dots: false,
			arrows: false,
			accessibility: false,
			autoplay: true,
			autoplaySpeed: 5000,
			draggable: false,
			pauseOnFocus: false,
			pauseOnHover: false,
			swipe: false,
			touchMove: false,
			slidesToShow: 1			
		});
		
	});	

})(jQuery);